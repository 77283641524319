.upload_c {
  display: inline-block;
}

.upload_bt {
  font-size: 14px;
  font-weight: 400;
  color: #2C81E5;
  width:398px;
  height:38px;
  line-height:38px;
  justify-content: center;
  align-items: center;
  border:1px solid #ddd;
  vertical-align: middle;
  display: flex;
}

.file {
  background-image: url(/assets/svgs/cloud.svg);
  background-position: center;
  background-repeat: no-repeat;
  width:24px;
  height:24px;
  cursor: pointer;
  margin-right:5px;
}

.isupload {
  flex:1;
  text-align: center;
  color:#999;
}

.delete {
  background-image: url(/assets/svgs/delete.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  width:40px;
  height:24px;
  cursor: pointer;
  margin-left:50px;
}
