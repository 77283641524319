.chain_rank_item {
    display: flex;
    justify-items: center;
    align-items: center;
    margin-top: 32px;
}
.chain_rank_name {
    max-width: 200px;
}
.chain_rank_progress {
    height: 20px;
    margin: 0px 17px;
}