.container {
  display: flex;
  height: auto;
  margin-top: 16px;
}

.container > div:first-child {
  width: 25%;
  overflow-y: auto;
  margin-right: 16px;
}

.container > div:last-child {
  width: 75%;
  position: relative;
}
