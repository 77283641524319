.search-nft {
    margin-top: 20px;
}

.nft-List {
    color: #666;
    overflow: hidden;
    .nft-item {
        width: 207px;
        height: 180px;
        margin: 20px 20px 20px 20px;
        float: left;
        text-align: center;
        border-radius: 7px;
        box-shadow: 0px 2px 6px 0px rgba(12,68,204,0.1);
        a {
            text-decoration-line: none;
        }
        .nft-img {
            width: 100%;
            height: 65%;
            img, video {
                width: 100%;
                height: 100%;
                border-radius: 7px 7px 0 0;
            }
        }
        p {
            font-size: 12px;
            margin-top: 9px;
            text-align: left;
            color: #333;
            font-weight: 400;
            text-indent: 14px;
            span {
                width: 60px;
                display: inline-block;
                text-align: left;
                text-indent: 0;
            }

            b {
                color: #185CFF;
                font-weight: normal;
            }
        }
    }
}

