/*!
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

 .block{
  width: 1360px;
  &::after{
    display: block;
    clear:both;
  }
}
.col {
  background: #FFFFFF;
  box-shadow: 0px 1px 20px 0px rgba(0,0,0,0.1);
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  padding: 18px 12px;
  min-height: 74px;
  width: 85%;
  // // height: 110px;
  display: inline-block;
  &::after{
    display: block;
    clear:both;
  }
}

.icon_c{
  width: 50px;
  height:50px;
  border-radius: 50%;
  margin-right: 10px;
  // display: flex;
  // justify-content: center;
  align-items: center;
  .icon_svg{
    width:50px;
    height: 50px;
    margin-top: 15px;
    // margin-right: 6px;
  }
  float: left;
}
.block_img{
  width: 62px;
  height: 62px;
  margin-left:16px;
  cursor: pointer;
  // display: none;
}
.block_item{
  // flex:1;
  float: left;
  // margin-left:16px;
}
.block_count{
  font-size: 16px;
  color: #323232;
  font-weight: 600;
  height:30px;
}
.block_name{
  margin-top: 4px;
  font-size: 14px;
  color: #999999;
  font-weight: 400;
  height:20px;
}

.block_i{
  width:252px;
  height: 139px;
  background-color: white;
  margin:0 20px 20px 0;
  float: left;
  overflow: hidden;
  transition: background-color 0.5s ;
  &:nth-child(3n){
    margin-right: 0;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  box-shadow: 0 4px 10px #142cb53b;
  
  &.block_height{
    .icon_c{
      background-color: #499bfc;
      box-shadow: 0 0 30px 0 #26252947 inset, 0 10px 25px 0 #499bfc;
      .icon_svg{
        color: white;
      }
    }
  }
  &.block_tx{
    .icon_c{
      background-color: #fd7063;
      box-shadow: 0 0 30px 0 #26252947 inset, 0 10px 25px 0 #fd7063;
      .icon_svg{
        color: white;
      }
    }
  }
  &.block_contract{
    .icon_c{
      background-color: #8771ef;
      box-shadow: 0 0 30px 0 #26252947 inset, 0 10px 25px 0 #8771ef;
      .icon_svg{
        color: white;
      }
    }
  }
  &.block_user{
    .icon_c{
      background-color: #ff9236;
      box-shadow: 0 0 30px 0 #26252947 inset, 0 10px 25px 0 #ff9236;
      .icon_svg{
        color: white;
      }
    }
  }
  &.block_org{
    .icon_c{
      background-color: #52CC6F;
      box-shadow: 0 0 30px 0 #26252947 inset, 0 10px 25px 0 #52CC6F;
      .icon_svg{
        color: white;
      }
    }
  }
  &.block_node{
    .icon_c{
      background-color: #00C7FF;
      box-shadow: 0 0 30px 0 #26252947 inset, 0 10px 25px 0 #00C7FF;
      .icon_svg{
        color: white;
      }
    }
  }
  &:hover{
    .block_count,.block_name{
      color:white;
    }
    &.block_height{
      background-color: #499bfc;
      .icon_c{
        background-color: white;
        box-shadow:0 10px 25px 0 #499bfc;
        .icon_svg{
          color: #499bfc;
        }
      }
    }
    &.block_tx{
      background-color: #fd7063;
      .icon_c{
        background-color: white;
        box-shadow: 0 10px 25px 0 #fd7063;
        .icon_svg{
          color: #fd7063;
        }
      }
    }
    &.block_contract{
      background-color: #8771ef;
      .icon_c{
        background-color: white;
        box-shadow: 0 10px 25px 0 #8771ef;
        .icon_svg{
          color: #8771ef;
        }
      }
    }
    &.block_user{
      background-color: #ff9236;
      .icon_c{
        background-color: white;
        box-shadow: 0 10px 25px 0 #ff9236;
        .icon_svg{
          color: #ff9236;
        }
      }
    }
    &.block_org{
      background-color: #52CC6F;
      .icon_c{
        background-color: white;
        box-shadow: 0 10px 25px 0 #52CC6F;
        .icon_svg{
          color: #52CC6F;
        }
      }
    }
    &.block_node{
      background-color: #00C7FF;
      .icon_c{
        background-color: white;
        box-shadow: 0 10px 25px 0 #00C7FF;
        .icon_svg{
          color: #00C7FF;
        }
      }
    }
  }
}

.unit {
  font-size: 14px;
  color: #999;
  font-weight: 400;
  margin-left: 4px;
}