/*!
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */
body{
  background-image: url(/assets/imgs/indexbg.png);
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: #f4faff;
}
.header{
  height:56px;
}
.header_c{
  height:56px;
  width:1300px;
  margin:0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header_logo{
  width:350px;
  height:53px;
  cursor: pointer;
}
.header_setting{
  display: flex;
  justify-content: end;
  align-items: center;
  width:295px;
  cursor: pointer;
}
.header_seeting_icon{
  cursor: pointer;
  margin-left:20px;
}
.header_tab {
  color:rgba(56, 54, 129, 1);
  font-weight: bold;
  font-size: 18px;
  &:hover {
    color: #383681;
    text-decoration: none;
  }
}

.active_tab{
  color: #185CFF;
}