/*!
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */
 .header{
  height:56px;
}
.header_c{
  height:56px;
  width:1300px;
  margin:0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header_logo{
  width:350px;
  height:53px;
  cursor: pointer;
}
.top{
  width:100%;
  height:325px;
  // background-color:#06163d;
  overflow: hidden;
  .top_info{
    margin:80px auto 0;
    display: flex;
    justify-content: center;
    width: 976px;
    .top_info_search_input{
      width:700px;
      background-color: white;
      overflow: hidden;
      border-radius: 4px;
      margin-right: 20px;
      input{
        width:660px;
        padding: 0 20px;
        height:50px;
        border:0;
        outline: none;
        color:#333;
        font-size: 16px;
        &:focus{
          box-shadow: 0 0 4px 0 #499bfc inset;
        }
      }
    }
    .top_info_search_bt{
      width: 100px;
      height: 50px;
      border-radius: 4px;
      background-color: #385BD2;
      background-image: url(/assets/imgs/search.png);
      background-position: 20px center;
      background-repeat: no-repeat;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-size: 18px;
      text-indent: 25px;
      color:white;
    }
    // .top_info_line{
    //   margin-top:32px;
    //   width:64px;
    //   height: 8px;
    //   background: #2984EF;
    // }
  }
}
.statistics{
  height:318px;
  width:1300px;
  margin: 40px auto 0;
  display: flex;
  justify-content: space-between;
}
.new_block{
  width:1300px;
  margin:40px auto 0;
  overflow: hidden;
}
.news{
  display: flex;
  justify-content: space-between;
  width: 1300px;
  margin:20px auto;
  padding-bottom: 50px;
  h3 {
    font-size: 20px;
    color: #323232;
    overflow: hidden;
    font-weight: bold;
    b {
      float: right;
      font-size: 14px;
    }
  }
}

h3 {
  width: 1300px;
  margin:20px auto;
  font-size: 20px;
  color: #323232;
  overflow: hidden;
  font-weight: bold;
  b {
    float: right;
    font-size: 14px;
    cursor: pointer;
  }
}

.table,.detail_table{
  margin-top:24px;
  thead{
    background-color: #E2EFFF;
    tr{
      background:transparent;
      height: 40px;
      th{
        padding: 4px 16px;
        &>div{
          font-size: 14px;
        }
      }
    }
  }
  tbody{
    tr{
      height: 40px;
      td{
        padding: 4px 16px;
        &>div{
          font-size: 14px;
        }
      }
    }
  }
  :global(.tea-btn){
    font-size: 14px;
    margin-left:10px;
  }
  :global(.tea-table)+:global(.tea-pagination){
    padding-top:20px;
  }
  :global(.tea-pagination) :global(.tea-pagination__text),:global(.tea-dropdown__value){
    font-size: 14px;
  }
}
.detail_table{
  margin-top: 0;
}

.sub_chain_list_search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}