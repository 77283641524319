/*!
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

.detail{
  :global(.tea-form-title){
    font-size: 16px;
    color: #333333;
    margin-top:36px;
  }
  :global(.tea-form){
    margin-top:15px;
    :global(.tea-form__label){
      label,legend{
        font-size:14px;
        color: #333333;
      }
    }
    :global(.tea-form__text){
      font-size: 14px;
      color: #333333;
    }
  }
  .pre{
    white-space: pre-wrap;
    border:1px solid #ddd;
    padding: 16px;
    height: auto !important;
    max-height: 126px;
    overflow-y: auto;
    font-size: 14px;
  }

  .decoration{
    display: inline-block;
    width: 69px;
    height: 0px;
    border: 2px solid;
    margin: 4px 10px;
  }
  .decoration-left {

    border-image: linear-gradient(270deg, rgba(24, 92, 255, 1), rgba(24, 92, 255, 0)) 2 2;

  }
  .decoration-right{

    border-image: linear-gradient(90deg, rgba(24, 92, 255, 1), rgba(24, 92, 255, 0)) 2 2;
  }
}

.invalid_link{
  color: gray;
}
.icon_style {
  margin-left: 12px;
}
