.progressBar {
    margin-left: 32px;
    margin-bottom: 80px;
    margin-top: 30px;
}

.icon {
    position: relative;
    img {
        width: 50px;
    }
    div {
        position: absolute;
        width: 70px;
        height: 40px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #000000;
        line-height: 16px;
        text-align: center;
        font-style: normal;
        text-transform: none;
        display: inline-block;
        left: -10px;
        top: 50px;
    }
}

.button_icon {
    width: 80px;
    height: 30px;
    line-height: 30px;
    background: #185CFF;
    box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.07);
    border-radius: 23px 23px 23px 23px;
    border: 3px solid #FFFFFF;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    text-align: center;
    font-style: normal;
    text-transform: none;
    display: inline-block;
}

.line {
    height: 10px;
    background: #ECF6FF;
    position: relative;
}

.line_item {
    background: #185CFF;
    height: 100%;
}
.line_icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    left: -25px;
    top: -16px;
}