/*!
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

.c{
  display: inline-block;
  margin-left: 30px;
}
.b{
  width:370px;
  height:30px;
  display: flex;
}
.input{
  flex:1;
}
.bt{
  background-color: #185CFF;
  padding:0 15px;
  font-size: 12px;
  color:white;
  font-weight: 300;
  line-height: 30px;
  cursor: pointer;
  margin-left: 10px;
  border-radius: 4px;
}
