.tea_cover_top_info_select {
    width: 110px;
    .tea-dropdown__header {
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 10px;
      border-radius: 4px;
      border: none;
      .tea-dropdown__value {
        width: 80px;
        font-size: 16px;
      }

      .tea-icon {
        position: static;
      }

      .ruyi-icon {
        position: static;
      }
    }
}

.tea_cover_button_primary {
  background-color: #185CFF;
  padding:0 15px;
  border-radius: 4px;
  color: #fff;
  &:hover {
    background-color: #185CFF;
    color: #fff;
  }
}

.tea_cover_input {
  width: 306px;
  border-radius: 4px;
  margin-left: 10px;
}

.tea_cover_select {
  .tea-dropdown-btn.tea-dropdown__header {
    border-radius: 4px;
  }
}

.tea-datepicker__input--range {
  width: 220px;
}
