/*!
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

.top{
  width:100%;
  height:485px;
  // background-color:#06163d;
  overflow: hidden;
  .top_info{
    margin:80px auto 0;
    display: flex;
    justify-content: space-between;
    width: 1010px;
    .top_info_search_input{
      width:700px;
      background-color: white;
      overflow: hidden;
      border-radius: 4px;
      input{
        width:660px;
        padding: 0 20px;
        height:50px;
        border:0;
        outline: none;
        color:#333;
        font-size: 16px;
        &:focus{
          box-shadow: 0 0 4px 0 #499bfc inset;
        }
      }
    }
    .top_info_search_bt{
      width: 100px;
      height: 50px;
      border-radius: 4px;
      background-color: #385BD2;
      background-image: url(/assets/imgs/search.png);
      background-position: 20px center;
      background-repeat: no-repeat;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-size: 18px;
      text-indent: 25px;
      color:white;
    }
    // .top_info_line{
    //   margin-top:32px;
    //   width:64px;
    //   height: 8px;
    //   background: #2984EF;
    // }
  }
}
.statistics{
  height:318px;
  width:1300px;
  margin: 40px auto 0;
  display: flex;
  justify-content: space-between;
}
.new_block{
  width:1300px;
  margin:40px auto 0;
  overflow: hidden;
}
.news{
  display: flex;
  justify-content: space-between;
  width: 1300px;
  margin:70px auto;
  padding-bottom: 50px;
}

.custom_search {
  display: flex;
  font-size: 18px;
  align-items: center;
  margin-left: 4px;
}
