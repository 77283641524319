body {
    margin: 0;
    font-family: Roboto, San Francisco, Helvetica Neue, Helvetica, Arial, PingFangSC-Light, Hiragina Sans GB, WenQuanYi Micro Hei, microsoft yahei ui, microsoft yahei, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #F5F8FA;
    /* 防止页面宽度抖动 */
    overflow-y: scroll;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
    min-width: 1300px;
}

.empty_list {
    display: block;
    width: 100%;
    min-height: 350px;
    background-image: url(./assets/imgs/empty.png);
    background-position: center;
    background-repeat: no-repeat;
}

.single_line {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.display-flex {
    display: flex;
}

.display-block {
    display: block;
}

.display-flex-center {
    display: flex;
    align-items: center;
}

.text-break {
    word-break: break-all;
}

.bubble-large {
    max-width: 350px;
}

.explorer-btn {
    cursor: pointer;
}

.explorer-link {
    font-size: 14px;
}

.explorer-btn:hover {
    text-decoration: underline;
}

.contract-event__table-content-column {
    max-height: 315px;
    overflow-y: auto;
}

.base-font {
    font-size: 14px;
}

.is-disabled {
    cursor: not-allowed;
}

.form-fixed-line-height {
    line-height: 30px;
}

.dbquery-table {
    margin-left: 15px;
}

.dbquery-tree {
}

.dbquery-tree .tea-tree__label-title {
    display: flex;
    width: 100%;
}

.dbquery-tree .tea-tree__label-title > span:first-child {
    display: flex;
}

.dbquery-column {
    margin-left: 20px;
}

.dbquery-column li {
    cursor: pointer;
}

.dbquery-run-params .tea-form__label {
    line-height: 30px;
}

.dbquery-run-result-bubble .tea-bubble__inner {
    max-height: 200px;
    overflow-y: auto;
}

.dbquery-run-result-modal .tea-dialog__body {
    max-height: 60vh;
    overflow-y: auto;
}

.monaco-editor-custom {
    height: 500px;
    padding-top: 16px;
    padding-bottom: 50px;
    background-color: #1e1e1e;
}

.monaco-editor-toolbar {
    width: 100%;
    position: absolute;
    top: 530px;
    left: 0;
    display: flex;
    justify-content: space-between;
}

.monaco-editor-toolbar .tea-btn {
    background-color: #2a2c2f;
    color: white;
    border: none;
}

.monaco-editor-toolbar .tea-btn:hover {
    background-color: #35373c;
}

/**
 * HACK，为修复之前tricky的全局样式设置
 */
.explorer-modal h3.tea-dialog__headertitle {
    width: auto !important;
}

.explorer-modal .tea-dialog__body {
    width: auto !important;
}

#webpack-dev-server-client-overlay {
    display: none;
}

.explorer-footer-version {
  text-align: center;
  padding-bottom: 20px;
  opacity: 0.5;
}

.upgrade-icon {
    position: fixed;
    bottom: 20px;
    left: 20px;
    width: 40px;
    height: 40px;
    background-color: #007bff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
}

.upgrade-icon::before {
    content: "";
    width: 24px;
    height: 24px;
    background-color: white;
    mask: url(data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M21 2v6h-6'/%3E%3Cpath d='M3 12a9 9 0 0 1 15-6.7L21 8'/%3E%3Cpath d='M3 22v-6h6'/%3E%3Cpath d='M21 12a9 9 0 0 1-15 6.7L3 16'/%3E%3C/svg%3E) no-repeat 50% 50%;
    mask-size: cover;
}
