/*!
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

.box{
  width:634px;
}
.bubble{
  margin-left:5px;
}
.more{
  font-weight: 400;
  font-size: 16px;
  color: #323232;
  img{
    vertical-align: middle;
    margin-bottom: 2px;
  }
}
.list{
  background-color: white;
  padding: 0 24px;
  margin-top:25px;
  height:979px;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
}
.deal_list{
  position: absolute;
  width:586px;
  left:24px;
}
.item{
  height:57px;
  padding:20px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-bottom: 1px solid #EEE;
  &:last-child{
    border-bottom: 0;
  }
}
.title{
  font-size: 16px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  a{
    font-size: 14px;
  }
}
.time{
  font-size: 14px;
  color: #758096;
  width:130px;
  white-space: nowrap;
  text-align: right;
}
.info{
  display: flex;
  align-items: center;
}
.path,.name{
  font-size: 14px;
  font-weight: 400;
  color: #758096;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.path{
  max-width: 200px;
}
.name{
  flex:1;
  max-width: 240px;
}
.point{
  height:0;
  width:0;
  border-left: 6px solid #323232;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  position: relative;
  margin:0 16px 0 30px;
  &::after{
    content:'';
    // border-left: 4px solid 323232;
    // border-top: 5px solid transparent;
    // border-bottom: 5px solid transparent;
    // position:absolute;
    height:1px;
    width: 12px;
    background-color: #323232;
    top:-0.5px;
    left:-18px;
    position:absolute;
  }
}
