/*!
 *
 *  *
 *  *  Copyright (C) THL A29 Limited, a Tencent company. All rights reserved.
 *  *  SPDX-License-Identifier: Apache-2.0
 *  *
 *
 */

.left{
  display: flex;
  font-weight: bold;
  font-size: 20px;
  color: #323232;
  align-items: center;
}
// .left_line{
//   width:4px;
//   height:20px;
//   background: #302987;
//   margin-right:8px;
// }
